import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/officialWebsite/NumberHome.vue'

Vue.use(VueRouter)
/**
 * 重写路由的push方法
 */
let routeType = process.env.VUE_APP_WEBSITE_MASTER;
let routerPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return routerPush.call(this, location).catch(error => error)
}
let routes = [
  {
    path: "/", //飞天云动
    name: 'ftyd',
    component: Home,
    meta: {favicon: "https://h5.ophyer.cn/official_website/other/favicon.ico"}
  },
  {
    path: '/about', //关于我们
    name: 'About',
    component: () => import('../views/officialWebsite/About.vue'),
    meta: {x: 0, y: 0, title: "关于我们"}
  },
  {
    path: '/cooperationCase', // 合作案例
    name: 'cooperationCase',
    component: () => import("../views/officialWebsite/CooperationCase.vue"),
    meta: {x: 0, y: 0, title: "合作案例"}
  },
  {
    path: '/CaseDetail', // 合作案例-详情
    name: 'CaseDetail',
    component: () => import("../views/officialWebsite/CaseDetail.vue"),
    meta: {x: 0, y: 0, title: "合作案例"}
  },
  {
    path: '/solutions', //解决方案
    name: 'solutions',
    component: () => import("../views/officialWebsite/Solutions.vue"),
    children: [
      {
        path: 'CateringTrade', // 餐饮行业
        name: 'CateringTrade',
        component: () => import( '../components/solutions/CateringTrade.vue'),
        // meta: {x: 0, y: 0, title: "全链路触点运营，提升转化"}
        meta: {x: 0, y: 0, title: "全链路一站式运营，提升转化"}
      },
      {
        path: 'AutomobileIndustry', // 汽车行业
        name: 'AutomobileIndustry',
        component: () => import( '../components/solutions/AutomobileIndustry.vue'),
        meta: {x: 0, y: 0, title: "3D模型+360°环物，专属购车体验"}
      },
      {
        path: 'OnlineRetailers', // 电商零售行业
        name: 'OnlineRetailers',
        component: () => import('../components/numberSolutions/NewRetail.vue'),
        // meta: {x: 0, y: 0, title: "线上/线下运营导流，全渠道用户运营"}
        meta: {x: 0, y: 0, title: "线上/线下导流，全渠道用户运营"}
      },
      {
        path: 'House', // 房产家居行业
        name: 'House',
        component: () => import('../components/numberSolutions/RealEstate.vue'),
        // meta: {x: 0, y: 0, title: "VR带看+全景直播，专注房产运营"}
        meta: {x: 0, y: 0, title: "VR带看+全景直播，专注房产数字化"}
      },
      {
        path: 'IntelligentCulturalTourism', // 智慧文旅
        name: 'IntelligentCulturalTourism',
        component: () => import('../components/solutions/IntelligentCulturalTourism.vue'),
        meta: {x: 0, y: 0, title: "助推文旅全方位、数字化产业升级"}
      },
      {
        path: 'WisdomEducation', // 智慧教育
        name: 'WisdomEducation',
        component: () => import('../components/solutions/WisdomEducation.vue'),
        meta: {x: 0, y: 0, title: "专注智慧教育，打造沉浸式互动教学新体验"}
      },
    ]
  },
  {
    path: '/serviceProducts', //产品与服务
    name: 'serviceProducts',
    component: () => import("../views/officialWebsite/ServiceProducts.vue"),
    children: [
      {
        path: 'SceneCloud', // 万景云
        name: 'SceneCloud',
        component: () => import('../components/products/SceneCloud.vue'),
        meta: {x: 0, y: 0, title: "万景云-定义科技文旅新方向"}
      },
      {
        path: 'LearnCloud', // 万学云
        name: 'LearnCloud',
        component: () => import('../components/products/LearnCloud.vue'),
        meta: {x: 0, y: 0, title: "万学云-一站式教学、运营、管理系统"}
      },
      {
        path: 'SaasCloud', // 万界云
        name: 'SaasCloud',
        component: () => import('../components/products/SassCloud.vue'),
        meta: {x: 0, y: 0, title: "万界云-全方位一体化商业定制服务"}
      },
      {
        path: 'WanqingCloud',// 万擎云
        name: 'WanqingCloud',
        component: () => import('../components/products/WanqingCloud.vue'),
        meta: {x: 0, y: 0, title: "万擎云-自研3D引擎，兼容性更强"}
      },
      {
        path: 'EfficiencyCloud',// 万效云
        name: 'EfficiencyCloud',
        component: () => import( '../components/products/EfficiencyCloud.vue'),
        meta: {x: 0, y: 0, title: "万效云-助力企业流量高效变现"}
      },
      {
        path: 'IP', // IP服务
        name: 'IP',
        component: () => import('../components/service/IP.vue'),
        meta: {x: 0, y: 0, title: "海量IP赋能商业品牌"}
      },
      {
        path: 'ShortMessage', // 短信服务
        name: 'ShortMessage',
        component: () => import('../components/service/ShortMessage.vue'),
        meta: {x: 0, y: 0, title: "高效、及时触发全球各地"}
      },
      {
        path: 'ShootingService',// 全景拍摄服务
        name: 'ShootingService',
        component: () => import('../components/service/ShootingService.vue'),
        meta: {x: 0, y: 0, title: "全景拍摄+制作一站式培训服务"}
      },
      {
        path: 'DigitalMuseum', // 数字博物馆
        name: 'DigitalMuseum',
        component: () => import('../components/service/DigitalMuseum.vue'),
        meta: {x: 0, y: 0, title: "文旅景区数字化管理服务"}
      },
      {
        path: 'AudioVisualCloud', // 视听云
        name: 'AudioVisualCoud',
        component: () => import( '../components/service/AudioVisualCloud.vue'),
        meta: {x: 0, y: 0, title: "助力企业轻松开始视听业务"}
      },
      {
        path: 'VRSceneShow',     // VR带看
        name: 'VRSceneShow',
        component: () => import( '../components/service/VRSceneShow.vue'),
        meta: {x: 0, y: 0, title: "高效实时在线互动，让导览更高效"}
      },
    ]
  },
  {
    path: '/h5', //h5官网
    name: 'h5',
    component: () => import('../views/h5/Index.vue'),
    children: [
      {
        path: "home", //主页
        name: 'h5Home',
        component: () => import("../views/h5/Home.vue"),
        meta: {x: 0, y: 0}
      },
      {
        path: 'productsH5', //产品中心
        name: 'productsH5',
        component: () => import("../views/h5/productsIndex.vue"),
        children: [
          {
            path: 'sceneCloud', // 万景云
            name: 'h5SceneCloud',
            component: () => import('../components/productsH5/SceneCloud.vue'),
            meta: {x: 0, y: 0}
          },
          {
            path: 'learnCloud', // 万学云
            name: 'h5LearnCloud',
            component: () => import('../components/productsH5/LearnCloud.vue'),
            meta: {x: 0, y: 0}
          },
          {
            path: 'saasCloud', // 万界云
            name: 'h5SaasCloud',
            component: () => import('../components/productsH5/SassCloud.vue'),
            meta: {x: 0, y: 0}
          },
          {
            path: 'wanQingCloud',// 万擎云
            name: 'h5WanQingCloud',
            component: () => import('../components/productsH5/WanQingCloud.vue'),
            meta: {x: 0, y: 0}
          },
          {
            path: 'efficiencyCloud',// 万效云
            name: 'h5EfficiencyCloud',
            component: () => import( '../components/productsH5/EfficiencyCloud.vue'),
            meta: {x: 0, y: 0}
          },
        ]
      },
      {
        path: "case", //合作案例
        name: 'h5Case',
        component: () => import("../views/h5/Case.vue"),
        meta: {x: 0, y: 0}
      },
      {
        path: "CaseDetail", //合作案例-详情
        name: 'h5CaseDetail',
        component: () => import("../views/h5/CaseDetail.vue"),
        meta: {x: 0, y: 0}
      },
      {
        path: 'about', //关于我们
        name: 'H5About',
        component: () => import('../views/h5/About.vue'),
        meta: {x: 0, y: 0}
      },
    ],
    meta: {x: 0, y: 0}
  },
  {
    path: "/wmyH5", //万目云h5
    name: 'wmyH5',
    component: () => import('../views/wmy/h5/Index.vue'),
    children: [
      {
        path: "wmyH5Home",
        name: 'wmyH5Home',
        component: () => import("../views/wmy/h5/Home.vue"),
        meta: {x: 0, y: 0, title: "万目云-赋能商业的智慧平台"}
      },
      {
        path: "wmyH5Case",
        name: 'wmyH5Case',
        component: () => import("../views/wmy/h5/wmyH5Case.vue"),
        meta: {x: 0, y: 0, title: "合作案例"}
      },
      {
        path: "wmyH5CaseDetail", //合作案例-详情
        name: 'wmyH5h5CaseDetail',
        component: () => import("../views/wmy/h5/wmyH5CaseDetail.vue"),
        meta: {x: 0, y: 0}
      },
      {
        path: "wmyH5About",
        name: 'wmyH5About',
        component: () => import("../views/wmy/h5/wmyH5About.vue"),
        meta: {x: 0, y: 0, title: "关于我们"}
      },
      {
        path: "wmyH5solutionAR",  // AR创意运营
        name: 'wmyH5solutionAR',
        component: () => import("../views/wmy/h5/solutionAR.vue"),
        meta: {x: 0, y: 0, title: "海量模板，一键生成AR趣味小游戏"}
      },
      {
        path: "wmyH5VRpanoramaWebsite",  // VR全景网站
        name: 'wmyH5VRpanoramaWebsite',
        component: () => import("../views/wmy/h5/VRpanoramaWebsite.vue"),
        meta: {x: 0, y: 0, title: "全景沉浸式展示，掌上体验“身临其境”"}
      },
      {
        path: "wmyH5BigCustomer",  // 大客户定制
        name: 'wmyH5BigCustomer',
        component: () => import("../views/wmy/h5/BigCustomer.vue"),
        meta: {x: 0, y: 0, title: "全景沉浸式展示，掌上体验“身临其境”"}
      },
      {
        path: 'agent', // 招商加盟
        name: 'wmyH5Agent',
        component: () => import( '../views/wmy/h5/Agent.vue'),
        meta: {x: 0, y: 0, title: "招商加盟"}
      },
    ]
  },
];
let numberRoutes = [
  // {
  //   path: "/", //飞天云动
  //   name: 'ftyd',
  //   component: Home,
  //   meta: {favicon: "https://h5.ophyer.cn/official_website/other/favicon.ico"}
  // },
  {
    path: '/about', //关于我们
    name: 'About',
    component: () => import('../views/officialWebsite/About.vue'),
    meta: {x: 0, y: 0, title: "关于我们"}
  },
  {
    path: '/cooperationCase', // 合作案例
    name: 'cooperationCase',
    component: () => import("../views/officialWebsite/CooperationCase.vue"),
    meta: {x: 0, y: 0, title: "合作案例"}
  },
  {
    path: '/CaseDetail', // 合作案例-详情
    name: 'CaseDetail',
    component: () => import("../views/officialWebsite/CaseDetail.vue"),
    meta: {x: 0, y: 0, title: "合作案例"}
  },
  {
    path: '/', // 教育
    name: 'education',
    component: () => import("../views/officialWebsite/education.vue"),
    meta: {x: 0, y: 0, title: "数字化教育"}
  },
  {
    path: '/numberSolutions', //数字化解决方案
    name: 'numberSolutions',
    component: () => import("../views/officialWebsite/NumberSolutions.vue"),
    children: [
      {
        path: 'Entertainment', // 娱乐
        name: 'Entertainment',
        component: () => import( '../components/numberSolutions/VrLiveBroadcast.vue'),
        meta: {x: 0, y: 0, title: "VR直播，在自己的场景中实时互动"}
      },
      {
        path: 'CulturalTourism', // 文博和旅游
        name: 'CulturalTourism',
        component: () => import( '../components/numberSolutions/Kulturtourismus.vue'),
        meta: {x: 0, y: 0, title: "文博艺术3D/VR数字化互动展示专家"}
      },
      {
        path: 'NewRetail', // 新零售
        name: 'NewRetail',
        component: () => import( '../components/numberSolutions/NewRetail.vue'),
        meta: {x: 0, y: 0, title: "VR云店助力店铺销售转化提升"}
      },
      {
        path: 'RealEstate', // 房地产
        name: 'RealEstate',
        component: () => import( '../components/numberSolutions/RealEstate.vue'),
        meta: {x: 0, y: 0, title: "提供沉浸式的线上看房体验"}
      },
      {
        path: 'IndustrialManufacturing', // 工业制造
        name: 'IndustrialManufacturing',
        component: () => import( '../components/numberSolutions/IndustrialManufacturing.vue'),
        meta: {x: 0, y: 0, title: "数字孪生助力工厂数字化转型"}
      },
      {
        path: 'VocationalTraining', // 职业培训
        name: 'VocationalTraining',
        component: () => import( '../components/numberSolutions/LavOccupation.vue'),
        meta: {x: 0, y: 0, title: "推动现代信息技术融入实验教学"}
      },
      {
        path: 'MetaverseSolution', // 元宇宙解决方案
        name: 'MetaverseSolution',
        component: () => import( '../components/numberSolutions/LavMetaverse.vue'),
        meta: {x: 0, y: 0, title: "一站式元宇宙解决方案"}
      },
      {
        path: 'MediaOperation', // 媒体运营
        name: 'MediaOperation',
        component: () => import( '../components/numberSolutions/LavMedia.vue'),
        meta: {x: 0, y: 0, title: "元宇宙时代的品牌运营专家"}
      }
    ]
  },
  {
    path: '/numberProducts', //数字化产品
    name: 'numberProducts',
    component: () => import("../views/officialWebsite/NumberProducts.vue"),
    children: [
      {
        path: 'FT', // FT引擎
        name: 'FT',
        component: () => import( '../components/numberProducts/Fte.vue'),
        meta: {x: 0, y: 0, title: "自主研发的三维引擎"}
      },
      {
        path: 'SaasUGC', // SaaS
        name: 'SaasUGC',
        component: () => import( '../components/numberProducts/SaasMetaverse.vue'),
        meta: {x: 0, y: 0, title: "元宇宙UGC工具"}
      },
      {
        path: 'Hardware', // 硬件产品
        name: 'Hardware',
        component: () => import( '../components/numberProducts/Hardware.vue'),
        meta: {x: 0, y: 0, title: "丰富的硬件体系适配各种展示场景"}
      },
      // {
      //   path: 'DigitalMan', // 数字人
      //   name: 'DigitalMan',
      //   component: () => import( '../components/numberProducts/DigitalMan.vue'),
      //   meta: {x: 0, y: 0, title: "AI与生产力结合的新物种"}
      // },
      //   path: 'DigitalAssets', // 数字资产
      //   name: 'DigitalAssets',
      //   component: () => import( '../components/numberProducts/Numberc.vue'),
      //   meta: {x: 0, y: 0, title: "更高效的数字资产评估和管理"}
      // },
    ]
  },
  {
    path: '/DigitalMan', // 数字人
    name: 'DigitalMan',
    component: () => import( '../components/numberProducts/DigitalMan.vue'),
    meta: {x: 0, y: 0, title: "AI与生产力结合的新物种"}
  },
  {
    path: '/aigc', // AIGC
    name: 'aigc',
    component: () => import( '../views/officialWebsite/aigc.vue'),
    meta: {x: 0, y: 0, title: "AIGC"}
  },
  {
    path: '/h5Number', //h5数字官网
    name: 'h5Number',
    component: () => import('../views/h5Number/Index.vue'),
    children: [
      {
        path: "home", //主页
        name: 'h5Home',
        component: () => import("../views/h5Number/education.vue"),
        meta: {x: 0, y: 0}
      },
      {
        path: 'numberProductsH5', //产品
        name: 'numberProductsH5',
        component: () => import("../views/h5Number/productsIndex.vue"),
        children: [
          {
            path: 'Fte', // FT引擎
            name: 'h5Fte',
            component: () => import('../components/numberProductsH5/Fte.vue'),
            meta: {x: 0, y: 0}
          },
          {
            path: 'SaasUGC', // saas
            name: 'h5SaasUGC',
            component: () => import('../components/numberProductsH5/SaasMetaverse.vue'),
            meta: {x: 0, y: 0}
          },
          {
            path: 'Hardware', // 硬件产品
            name: 'h5Hardware',
            component: () => import('../components/numberProductsH5/Hardware.vue'),
            meta: {x: 0, y: 0}
          },
          {
            path: 'DigitalMan',// 数字人
            name: 'h5DigitalMan',
            component: () => import('../components/numberProductsH5/DigitalMan.vue'),
            meta: {x: 0, y: 0}
          },
          /* {
            path: 'DigitalAssets',// 数字资产
            name: 'h5DigitalAssets',
            component: () => import( '../components/numberProductsH5/Numberc.vue'),
            meta: {x: 0, y: 0}
          }, */
        ]
      },
      {
        path: 'numberSolutionsH5', //解决方案
        name: 'numberSolutionsH5',
        component: () => import("../views/h5Number/SolutionsIndex.vue"),
        children: [
          {
            path: 'Entertainment', // 娱乐
            name: 'h5Entertainment',
            component: () => import('../components/numberSolutionsH5/VrLiveBroadcast.vue'),
            meta: {x: 0, y: 0}
          },
          {
            path: 'CulturalTourism', // 文博和旅游
            name: 'h5CulturalTourism',
            component: () => import('../components/numberSolutionsH5/Kulturtourismus.vue'),
            meta: {x: 0, y: 0}
          },
          {
            path: 'NewRetail', // 新零售
            name: 'h5NewRetail',
            component: () => import('../components/numberSolutionsH5/NewRetail.vue'),
            meta: {x: 0, y: 0}
          },
          {
            path: 'RealEstate', // 房地产
            name: 'h5RealEstate',
            component: () => import('../components/numberSolutionsH5/RealEstate.vue'),
            meta: {x: 0, y: 0}
          },
          {
            path: 'IndustrialManufacturing', // 工业制造
            name: 'h5IndustrialManufacturing',
            component: () => import('../components/numberSolutionsH5/IndustrialManufacturing.vue'),
            meta: {x: 0, y: 0}
          },
          {
            path: 'VocationalTraining', // 职业培训
            name: 'h5VocationalTraining',
            component: () => import('../components/numberSolutionsH5/LavOccupation.vue'),
            meta: {x: 0, y: 0}
          },
          {
            path: 'MetaverseSolution', // 元宇宙解决方案
            name: 'h5MetaverseSolution',
            component: () => import('../components/numberSolutionsH5/LavMetaverse.vue'),
            meta: {x: 0, y: 0}
          },
          {
            path: 'MediaOperation', // 媒体运营
            name: 'h5MediaOperation',
            component: () => import('../components/numberSolutionsH5/LavMedia.vue'),
            meta: {x: 0, y: 0}
          },
        ]
      },
      {
        path: "case", //合作案例
        name: 'h5Case',
        component: () => import("../views/h5Number/Case.vue"),
        meta: {x: 0, y: 0}
      },
      {
        path: "CaseDetail", //合作案例-详情
        name: 'h5CaseDetail',
        component: () => import("../views/h5Number/CaseDetail.vue"),
        meta: {x: 0, y: 0}
      },
      {
        path: 'about', //关于我们
        name: 'H5About',
        component: () => import('../views/h5Number/About.vue'),
        meta: {x: 0, y: 0}
      },
      {
        path: 'education', // 教育
        name: 'h5Education',
        component: () => import("../views/h5Number/education.vue"),
        meta: {x: 0, y: 0, title: "教育"}
      },
      {
        path: 'DigitalMan',// 数字人
        name: 'h5DigitalMan',
        component: () => import('../components/numberProductsH5/DigitalMan.vue'),
        meta: {x: 0, y: 0}
      },
      {
        path: 'aigc', // AIGC
        name: 'h5aigc',
        component: () => import( '../components/numberProductsH5/aigc.vue'),
        meta: {x: 0, y: 0, title: "AIGC"}
      },
    ],
    meta: {x: 0, y: 0}
  },
  // app下载落地页
  {
    path: "/appdownload", //AR打卡
    name: 'appdownload',
    component: () => import('../views/appdownload/Index'),
    children: [
      {
        path: "Home",
        name: 'appdownloadHome',
        component: () => import("../views/appdownload/Home.vue"),
        meta: {x: 0, y: 0, title: "app下载", favicon: "https://h5.ophyer.cn/official_website/other/favicon1.ico"}
      }
    ]
  },

];
let routeWmy = [
  {
    path: "/", //万目云
    name: 'wmyHome',
    component: () => import('../views/wmy/WmyHome.vue'),
    meta: {x: 0, y: 0, title: "万目云-赋能商业的智慧平台", favicon: "https://h5.ophyer.cn/official_website/other/favicon1.ico"}
  },
  {
    path: "/case",
    name: '/case',
    component: () => import("../views/wmy/Case.vue"),
    meta: {x: 0, y: 0, title: "合作案例"}
  },
  {
    path: "/newCase",
    name: 'newCase',
    component: () => import("../views/wmy/newCase.vue"),
    meta: {x: 0, y: 0, title: "精品案例"}
  },
  {
    path: '/dispatch',
    name: 'dispatch',
    component: () => import("../views/dispatch/index.vue"),
    meta: {x: 0, y: 0, title: "派单系统"}
  },
  {
    path: "/WonderfulCases",
    name: 'WonderfulCases',
    component: () => import("../views/wmy/WonderfulCases.vue"),
    meta: {x: 0, y: 0, title: "精彩案例"}
  },
  {
    path: '/CaseDetail', // 合作案例-详情
    name: 'CaseDetail',
    component: () => import("../views/officialWebsite/CaseDetail.vue"),
    meta: {x: 0, y: 0, title: "合作案例"}
  },
  {
    path: "/solutions",
    name: 'wmySolutions',
    component: () => import("../views/wmy/Solutions.vue"),
    children: [
      {
        path: "solutionAR",  // 解决方案（AR互动运营）
        name: 'solutionAR',
        component: () => import("../components/wmy/solutions/SolutionAR.vue"),
        meta: {x: 0, y: 0, title: "海量模板，一键生成AR趣味小游戏"}
      },
      {
        path: "solutionVR",  // 解决方案（VR全景运营）
        name: 'solutionVR',
        component: () => import("../components/wmy/solutions/SolutionVR.vue"),
        meta: {x: 0, y: 0, title: "全景沉浸式展示，掌上体验“身临其境”"}
      },
    ]
  },
  {
    path: '/InvestmentPromotion', // 招商加盟
    name: 'InvestmentPromotion',
    component: () => import( '../views/wmy/InvestmentPromotion.vue'),
    meta: {x: 0, y: 0, title: "招商加盟"}
  },
  {
    path: "/wmyH5", //万目云h5
    name: 'wmyH5',
    component: () => import('../views/wmy/h5/Index.vue'),
    children: [
      {
        path: "wmyH5Home",
        name: 'wmyH5Home',
        component: () => import("../views/wmy/h5/Home.vue"),
        meta: {x: 0, y: 0, title: "万目云-赋能商业的智慧平台", favicon: "https://h5.ophyer.cn/official_website/other/favicon1.ico"}
      },
      {
        path: "wmyH5Case",
        name: 'wmyH5Case',
        component: () => import("../views/wmy/h5/wmyH5Case.vue"),
        meta: {x: 0, y: 0, title: "合作案例"}
      },
      {
        path: "wmyH5CaseDetail", //合作案例-详情
        name: 'wmyH5h5CaseDetail',
        component: () => import("../views/wmy/h5/wmyH5CaseDetail.vue"),
        meta: {x: 0, y: 0}
      },
      {
        path: "wmyH5About",
        name: 'wmyH5About',
        component: () => import("../views/wmy/h5/wmyH5About.vue"),
        meta: {x: 0, y: 0, title: "关于我们"}
      },
      {
        path: "wmyH5solutionAR",  // AR创意运营
        name: 'wmyH5solutionAR',
        component: () => import("../views/wmy/h5/solutionAR.vue"),
        meta: {x: 0, y: 0, title: "海量模板，一键生成AR趣味小游戏"}
      },
      {
        path: "wmyH5VRpanoramaWebsite",  // VR全景网站
        name: 'wmyH5VRpanoramaWebsite',
        component: () => import("../views/wmy/h5/VRpanoramaWebsite.vue"),
        meta: {x: 0, y: 0, title: "全景沉浸式展示，掌上体验“身临其境”"}
      },
      {
        path: "wmyH5BigCustomer",  // 大客户定制
        name: 'wmyH5BigCustomer',
        component: () => import("../views/wmy/h5/BigCustomer.vue"),
        meta: {x: 0, y: 0, title: "全景沉浸式展示，掌上体验“身临其境”"}
      },
      {
        path: 'agent', // 招商加盟
        name: 'wmyH5Agent',
        component: () => import( '../views/wmy/h5/Agent.vue'),
        meta: {x: 0, y: 0, title: "招商加盟"}
      },
      {
        path: 'extensionH5', 
        name: 'extensionH5',
        component: () => import("../views/wmy/h5/extensionH5.vue"),
        meta: {x: 0, y: 0, title: "我的推广"}
      },
    ]
  },
  {
    path: "/marketingDiversion", //运营导流
    name: 'marketingDiversion',
    component: () => import('../views/marketingDiversion/Index'),
    children: [
      {
        path: "home",
        name: 'marketingDiversionHome',
        component: () => import("../views/marketingDiversion/Home.vue"),
        meta: {x: 0, y: 0, title: "助力企业流量高效变现", favicon: "https://h5.ophyer.cn/official_website/other/favicon1.ico"}
      },
      {
        path: "serviceContent", //服务内容
        name: 'serviceContent',
        component: () => import("../views/marketingDiversion/ServiceContent.vue"),
        meta: {x: 0, y: 0, title: "助力企业流量高效变现"}
      },
      {
        path: 'bigData', //大数据
        name: 'bigData',
        component: () => import( '../views/marketingDiversion/BigData.vue'),
        meta: {x: 0, y: 0, title: "助力企业流量高效变现"}
      },
    ]
  },
  {
    path: "/sceneEditor3D", //3D场景编辑器
    name: 'sceneEditor3D',
    component: () => import('../views/SceneEditor3D/Index'),
    children: [
      {
        path: "Home",
        name: 'sceneEditor3DHome',
        component: () => import("../views/SceneEditor3D/Home.vue"),
        meta: {x: 0, y: 0, title: "自研3D引擎，兼容性更强", favicon: "https://h5.ophyer.cn/official_website/other/favicon1.ico"}
      },
      {
        path: "solution", //解决方案
        name: 'sceneEditor3DSolution',
        component: () => import("../views/SceneEditor3D/Solution.vue"),
        meta: {x: 0, y: 0, title: "自研3D引擎，兼容性更强"}
      },
      {
        path: 'case', //案例
        name: 'sceneEditor3DCase',
        component: () => import( '../views/SceneEditor3D/Case.vue'),
        meta: {x: 0, y: 0, title: "合作案例"}
      },
    ]
  },
  // AR打卡
  {
    path: "/arPunch", //AR打卡
    name: 'arPunch',
    component: () => import('../views/arPunch/Index'),
    children: [
      {
        path: "Home",
        name: 'arPunchHome',
        component: () => import("../views/arPunch/Home.vue"),
        meta: {x: 0, y: 0, title: "文旅景区小程序互动游戏方案", favicon: "https://h5.ophyer.cn/official_website/other/favicon1.ico"}
      },
      {
        path: "solution", //解决方案
        name: 'arPunchSolution',
        component: () => import("../views/arPunch/Solution.vue"),
        meta: {x: 0, y: 0, title: "文旅景区小程序互动游戏方案"}
      },
    ]
  },
  // 公众号助手
  {
    path: "/assistantAccount",
    name: 'assistantAccount',
    component: () => import('../views/assistantAccount/Index'),
    children: [
      {
        path: "Home",
        name: 'assistantAccountHome',
        component: () => import("../views/assistantAccount/Home.vue"),
        meta: {x: 0, y: 0, title: "满足企业公众号运营需求，提升粉丝转化", favicon: "https://h5.ophyer.cn/official_website/other/favicon1.ico"}
      },
      {
        path: "crm",
        name: 'crm',
        component: () => import("../views/assistantAccount/Crm.vue"),
        meta: {x: 0, y: 0, title: "满足企业公众号运营需求，提升粉丝转化"}
      },
      {
        path: "marketing",
        name: 'marketing',
        component: () => import("../views/assistantAccount/Marketing.vue"),
        meta: {x: 0, y: 0, title: "满足企业公众号运营需求，提升粉丝转化"}
      },

    ]
  },
  // Vr全景网站
  {
    path: "/vrPanorama",
    name: 'vrPanorama',
    component: () => import('../views/vrPanorama/Index'),
    children: [
      {
        path: "home",
        name: 'vrPanoramaHome',
        component: () => import("../views/vrPanorama/Home.vue"),
        meta: {x: 0, y: 0, title: "VR全景网站", favicon: "https://h5.ophyer.cn/official_website/other/favicon1.ico"}
      },
      {
        path: "solution", //解决方案
        name: 'vrPanoramaSolution',
        component: () => import("../views/vrPanorama/Solution"),
        meta: {x: 0, y: 0, title: "VR全景网站"}
      },
      {
        path: "shot", //拍摄服务
        name: 'shot',
        component: () => import("../views/vrPanorama/Shot"),
        meta: {x: 0, y: 0, title: "拍摄服务"}
      },
      {
        path: "case", //案例
        name: 'vrPanoramaCase',
        component: () => import("../views/vrPanorama/Case"),
        meta: {x: 0, y: 0, title: "合作案例"}
      },
      {
        path: 'agent', //招商加盟
        name: 'agent',
        component: () => import( '../views/vrPanorama/Agent'),
        meta: {x: 0, y: 0, title: "招商加盟"}
      },
    ]
  },
  {
    path: "/ARcreativeMarketing", //AR创意运营
    name: 'ARcreativeMarketing',
    component: () => import('../views/ARcreativeMarketing/Index.vue'),
    children: [
      {
        path: "home",
        name: 'ARcreativeMarketingHome',
        component: () => import("../views/ARcreativeMarketing/Home.vue"),
        meta: {x: 0, y: 0, title: "AR创意运营", favicon: "https://h5.ophyer.cn/official_website/other/favicon1.ico"}
      },
      {
        path: 'ARSolution', //解决方案
        name: 'ARSolution',
        component: () => import( '../views/ARcreativeMarketing/ARSolution.vue'),
        meta: {x: 0, y: 0, title: "AR创意运营"}
      },
      {
        path: 'ARcase', //案例
        name: 'ARcase',
        component: () => import( '../views/ARcreativeMarketing/ARcase.vue'),
        meta: {x: 0, y: 0, title: "合作案例"}
      },
      {
        path: "ARInvestmentPromotionAgency", //招商加盟
        name: 'ARInvestmentPromotionAgency',
        component: () => import("../views/ARcreativeMarketing/ARInvestmentPromotionAgency.vue"),
        meta: {x: 0, y: 0, title: "招商加盟"}
      },
    ]
  },
  //万目云-》电商
  {
    path: "/OnlineRetailersStore", //电商
    name: 'OnlineRetailersStore',
    component: () => import('../views/OnlineRetailersStore/Index'),
    children: [
      {
        path: "home",
        name: 'OnlineRetailersStoreHome',
        component: () => import("../views/OnlineRetailersStore/Home.vue"),
        meta: {x: 0, y: 0, title: "专注私域流量精准运营", favicon: "https://h5.ophyer.cn/official_website/other/favicon1.ico"}
      },
      {
        path: "solution", //解决方案
        name: 'OnlineRetailersStoreSolution',
        component: () => import("../views/OnlineRetailersStore/Solution.vue"),
        meta: {x: 0, y: 0, title: "专注私域流量精准运营"}
      },
      {
        path: 'case', //合作案例
        name: 'OnlineRetailersStoreCase',
        component: () => import( '../views/OnlineRetailersStore/Case.vue'),
        meta: {x: 0, y: 0, title: "合作案例"}
      },
    ]
  },
  //万目云-》IP授权
  {
    path: "/Ip", //IP
    name: 'Ip',
    component: () => import('../views/IP/Index'),
    children: [
      {
        path: "home",
        name: 'IpHome',
        component: () => import("../views/IP/Home.vue"),
        meta: {x: 0, y: 0, title: "海量IP赋能商业品牌", favicon: "https://h5.ophyer.cn/official_website/other/favicon1.ico"}
      },
      {
        path: "solution", //解决方案
        name: 'IpSolution',
        component: () => import("../views/IP/Solution.vue"),
        meta: {x: 0, y: 0, title: "海量IP赋能商业品牌"}
      },
      {
        path: 'case', //案例
        name: 'IpCase',
        component: () => import( '../views/IP/Case.vue'),
        meta: {x: 0, y: 0, title: "合作案例"}
      },
    ]
  },
  //万目云-》AI智能
  {
    path: "/Ai", //AI智能
    name: 'Ai',
    component: () => import('../views/AI/Index'),
    children: [
      {
        path: "AIHome",
        name: 'AIHome',
        component: () => import("../views/AI/AIHome.vue"),
        meta: {x: 0, y: 0, title: "人工智能商业解决方案", favicon: "https://h5.ophyer.cn/official_website/other/favicon1.ico"}
      },
      {
        path: "solution", //解决方案
        name: 'AiSolution',
        component: () => import("../views/AI/Solution.vue"),
        meta: {x: 0, y: 0, title: "人工智能商业解决方案"}
      },
      {
        path: 'case', //案例
        name: 'AiCase',
        component: () => import( '../views/AI/Case.vue'),
        meta: {x: 0, y: 0, title: "合作案例"}
      },
    ]
  },
  // 视听云
  {
    path: "/audiovisualCloud", // 视听云
    name: 'audiovisualCloud',
    component: () => import('../views/audiovisualCloud/Index'),
    children: [
      {
        path: "home",
        name: 'audiovisualCloudHome',
        component: () => import("../views/audiovisualCloud/Home.vue"),
        meta: {x: 0, y: 0, title: "助力企业轻松开始视听业务", favicon: "https://h5.ophyer.cn/official_website/other/favicon1.ico"}
      },
      {
        path: "audioSolution", // 解决方案
        name: 'audioSolution',
        component: () => import("../views/audiovisualCloud/audioSolution.vue"),
        meta: {x: 0, y: 0, title: "助力企业轻松开始视听业务"}
      },
      {
        path: 'audioCase', // 案例
        name: 'audioCase',
        component: () => import( '../views/audiovisualCloud/audioCase.vue'),
        meta: {x: 0, y: 0, title: "合作案例"}
      },
      {
        path: 'application', // 申请页面
        name: 'application',
        component: () => import( '../views/audiovisualCloud/Application.vue'),
        meta: {x: 0, y: 0, title: "助力企业轻松开始视听业务"}
      },
    ]
  },
  // 大数据
  {
    path: "/bigDataAnalysis", // 大数据
    name: 'bigDataAnalysis',
    component: () => import('../views/bigDataAnalysis/Index'),
    children: [
      {
        path: "home",
        name: 'bigDataAnalysisHome',
        component: () => import("../views/bigDataAnalysis/Home.vue"),
        meta: {x: 0, y: 0, title: "数据驱动业务增长专家", favicon: "https://h5.ophyer.cn/official_website/other/favicon1.ico"}
      },
      {
        path: "productService", // 产品服务
        name: 'productService',
        component: () => import("../views/bigDataAnalysis/productService.vue"),
        meta: {x: 0, y: 0, title: "数据驱动业务增长专家"}
      },
      {
        path: 'industrySolution', // 解决方案
        name: 'industrySolution',
        component: () => import( '../views/bigDataAnalysis/industrySolution.vue'),
        meta: {x: 0, y: 0, title: "数据驱动业务增长专家"}
      },
    ]
  },
  // 大客户定制
  {
    path: "/customers", // 大客户定制
    name: 'customers',
    component: () => import('../views/customers/Index'),
    children: [
      {
        path: "home",
        name: 'customersHome',
        component: () => import("../views/customers/Home.vue"),
        meta: {x: 0, y: 0, title: "全方位一体化商业定制服务", favicon: "https://h5.ophyer.cn/official_website/other/favicon1.ico"}
      },
      {
        path: "customersSolution", // 解决方案
        name: 'customersSolution',
        component: () => import("../views/customers/customersSolution.vue"),
        meta: {x: 0, y: 0, title: "全方位一体化商业定制服务"}
      },
      {
        path: "customersCase", // 案例
        name: 'customersCase',
        component: () => import("../views/customers/customersCase.vue"),
        meta: {x: 0, y: 0, title: "合作案例"}
      }
    ]
  },
  // 短信服务
  {
    path: "/shortMessage",
    name: 'shortMessage',
    component: () => import('../views/shortMessage/Index'),
    children: [
      {
        path: "Home",
        name: 'shortMessageHome',
        component: () => import("../views/shortMessage/Home.vue"),
        meta: {x: 0, y: 0, title: "高效、及时触发全球各地", favicon: "https://h5.ophyer.cn/official_website/other/favicon1.ico"}
      },
      {
        path: "solution", // 解决方案
        name: 'shortMessageSolution',
        component: () => import("../views/shortMessage/Solution.vue"),
        meta: {x: 0, y: 0, title: "高效、及时触发全球各地"}
      },
    ]
  },
  {
    path: '/h5', //h5官网
    name: 'h5',
    component: () => import('../views/h5/Index.vue'),
    children: [
      {
        path: "home", //主页
        name: 'h5Home',
        component: () => import("../views/h5/Home.vue"),
        meta: {x: 0, y: 0, title: "万目云-赋能商业的智慧平台", favicon: "https://h5.ophyer.cn/official_website/other/favicon1.ico"},
      },
      {
        path: 'productsH5', //产品中心
        name: 'productsH5',
        component: () => import("../views/h5/productsIndex.vue"),
        children: [
          {
            path: 'sceneCloud', // 万景云
            name: 'h5SceneCloud',
            component: () => import('../components/productsH5/SceneCloud.vue'),
            meta: {x: 0, y: 0}
          },
          {
            path: 'learnCloud', // 万学云
            name: 'h5LearnCloud',
            component: () => import('../components/productsH5/LearnCloud.vue'),
            meta: {x: 0, y: 0}
          },
          {
            path: 'saasCloud', // 万界云
            name: 'h5SaasCloud',
            component: () => import('../components/productsH5/SassCloud.vue'),
            meta: {x: 0, y: 0}
          },
          {
            path: 'wanQingCloud',// 万擎云
            name: 'h5WanQingCloud',
            component: () => import('../components/productsH5/WanQingCloud.vue'),
            meta: {x: 0, y: 0}
          },
          {
            path: 'efficiencyCloud',// 万效云
            name: 'h5EfficiencyCloud',
            component: () => import( '../components/productsH5/EfficiencyCloud.vue'),
            meta: {x: 0, y: 0}
          },
        ]
      },
      {
        path: "case", //合作案例
        name: 'h5Case',
        component: () => import("../views/h5/Case.vue"),
        meta: {x: 0, y: 0}
      },
      {
        path: "CaseDetail", //合作案例-详情
        name: 'h5CaseDetail',
        component: () => import("../views/h5/CaseDetail.vue"),
        meta: {x: 0, y: 0}
      },
      {
        path: 'about', //关于我们
        name: 'H5About',
        component: () => import('../views/h5/About.vue'),
        meta: {x: 0, y: 0}
      },
    ],
  },
  //我的推广
  {
    path: '/extensionPC', 
    name: 'extensionPC',
    component: () => import("../views/extension/extensionPC.vue"),
    meta: {x: 0, y: 0, title: "我的推广"}
  },
  {
    path: '/extensionH5', 
    name: 'extensionH5',
    component: () => import("../views/extension/extensionH5.vue"),
    meta: {x: 0, y: 0, title: "我的推广"}
  },
  //派单系统
  {
    path: '/dispatch', 
    name: 'dispatch',
    component: () => import("../views/dispatch/index.vue"),
    meta: {x: 0, y: 0, title: "派单系统"}
  },
  // {
  //   path: "/WonderfulCases",
  //   name: '/WonderfulCases',
  //   component: () => import("../views/wmy/WonderfulCases.vue"),
  //   meta: {x: 0, y: 0, title: "精彩案例"}
  // },
];
let routeMetaverse = [
  {  //元宇宙
    path: "/",
    name: 'MetaverseHome',
    component: () => import('../views/metaverse/pc/Home.vue'),
    meta: {x: 0, y: 0, title: "飞天元宇宙", favicon: "https://h5.ophyer.cn/official_website/other/favicon2.ico"}
  },
  {
    path: '/Course',
    name: 'MetaverseCourse',
    component: () => import( '../views/metaverse/pc/Course.vue'),
    meta: {x: 0, y: 0, title: "飞天元宇宙"}
  },
  {
    path: '/develop', // 开发者
    name: 'MetaverseDevelop',
    component: () => import( '../views/metaverse/develop/Index.vue'),
    children: [
      {
        path: 'Home',
        name: 'DevelopHome',
        component: () => import( '../views/metaverse/develop/Home.vue'),
        meta: {x: 0, y: 0, title: "飞天元宇宙", favicon: "https://h5.ophyer.cn/official_website/other/favicon2.ico"},
      },
      {
        path: 'Experience', // 教程
        name: 'DevelopExperience',
        component: () => import( '../views/metaverse/develop/Experience.vue'),
        meta: {x: 0, y: 0, title: "飞天元宇宙"}
      },
      {
        path: 'API', // 教程
        name: 'DevelopAPI',
        component: () => import( '../views/metaverse/develop/API.vue'),
        meta: {x: 0, y: 0, title: "飞天元宇宙"}
      },
      {
        path: 'ExperienceDetail', // 教程详情
        name: 'MetaverseExperienceDetail',
        component: () => import( '../views/metaverse/develop/ExperienceDetail.vue'),
        meta: {x: 0, y: 0, title: "飞天元宇宙"}
      }
    ],
  },
  {
    path: '/h5', //元宇宙移动端
    name: 'metaverseH5',
    component: () => import("../views/metaverse/h5/Index.vue"),
    meta: {x: 0, y: 0, title: "飞天元宇宙", favicon: "https://h5.ophyer.cn/official_website/other/favicon2.ico"},
    children: [
      {
        path: 'Home',
        name: 'metaverseH5Home',
        component: () => import( '../views/metaverse/h5/Home.vue'),
        meta: {x: 0, y: 0, title: "飞天元宇宙"}
      },
      {
        path: 'Course',
        name: 'h5MetaverseCourse',
        component: () => import( '../views/metaverse/h5/Course.vue'),
        meta: {x: 0, y: 0, title: "飞天元宇宙"}
      },
      {
        path: 'develop', // 开发者
        name: 'MetaverseH5Develop',
        component: () => import( '../views/metaverse/h5/develop/Index.vue'),
        children: [
          {
            path: 'Home',
            name: 'DevelopH5Home',
            component: () => import( '../views/metaverse/h5/develop/Home.vue'),
            meta: {x: 0, y: 0, title: "飞天元宇宙"}
          },
          {
            path: 'Experience', // 教程
            name: 'DevelopH5Experience',
            component: () => import( '../views/metaverse/h5/develop/Experience.vue'),
            meta: {x: 0, y: 0, title: "飞天元宇宙"}
          },
          {
            path: 'ExperienceDetail', // 教程详情
            name: 'DevelopExperienceDetail',
            component: () => import( '../views/metaverse/h5/develop/ExperienceDetail.vue'),
            meta: {x: 0, y: 0, title: "飞天元宇宙"}
          },
          {
            path: 'API', // 教程
            name: 'DevelopH5API',
            component: () => import( '../views/metaverse/h5/develop/API.vue'),
            meta: {x: 0, y: 0, title: "飞天元宇宙"}
          },
          // {
          //   path: 'ExperienceDetail', // 教程详情
          //   name: 'MetaverseH5ExperienceDetail',
          //   component: () => import( '../views/metaverse/h5/ExperienceDetail.vue'),
          //   meta: {x: 0, y: 0, title: ""}
          // }
        ],
      },
    ]
  },
];

console.log(routes);
let routeInfo = routeType === "metaverse" ? routeMetaverse : routeType === "wmy" ? routeWmy : numberRoutes;
let router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  //路由变化时回到顶部
  scrollBehavior: () => {
    // return to.meta || 0;
    return {x: 0, y: 0};
  },
  routes: routeInfo
})
router.afterEach((to) => {
  // document.title = to.meta.title || "飞天云动-助力品牌运营";
  // document.title = to.meta.title || "构建3D世界，让人类进入元宇宙时代";
  document.title = to.meta.title || "飞天云动构建3D世界,让人类进入元宇宙时代";
  if(to.meta && to.meta.favicon){
    document.head.querySelector("link").href = to.meta.favicon;
  }
})
export default router
